import * as React from 'react';
import {
  Input,
  Popover,
  PopoverTrigger,
  PopoverContent,
  AlertDialog,
  AlertDialogContent,
  AlertDialogTitle,
  AlertDialogCancel,
} from '@ui/components';
import { Icon } from '@icons/icons';
import Button from '@ui/components/ui/button/Button';
import { type SearchAirportFieldsFragment } from '../../../graphql/generated/graphql';
import { useTranslations } from 'next-intl';
import { useParams, useSearchParams } from 'next/navigation';
import Image from 'next/image';
import { GroupAirportsByCountry } from '../../../helpers/group-airport-by-country/group-airport-by-country';

type PopoverPropsType = {
  airports: Array<SearchAirportFieldsFragment>;
  onAirportSelect: (airport: string) => void;
  ButtonVariant?: 'dropdown' | 'input' | 'dropdownGray';
  buttonSize?: 'dropdown' | 'input';
  buttonRadius?: 'roundedLeft' | 'fullRounded';
  popoverSide?: 'left' | 'bottom';
  isAutoScroll?: boolean;
};

export const SearchAirport = ({
  airports,
  onAirportSelect,
  ButtonVariant = 'input',
  buttonSize = 'dropdown',
  buttonRadius = 'fullRounded',
  popoverSide = 'left',
  isAutoScroll = false,
}: PopoverPropsType) => {
  const [isAlertOpen, setIsAlertOpen] = React.useState(false);
  const [searchTerm, setSearchTerm] = React.useState('');
  const [filteredCountries, setFilteredCountries] = React.useState(airports);
  const [selectedCountry, setSelectedCountry] = React.useState<string | undefined>();
  // set the default value of selectedAirport to the first airport based on the search param
  const params = useParams(); // For params
  const searchParams = useSearchParams(); // For wuery params

  // Detect airport
  // since it is a custom component
  const airportParam = Array.isArray(params?.airport)
    ? decodeURIComponent(params.airport[0] || '')
    : // need to comment this out because it doesn't match with eslint and prettier
      // eslint-disable-next-line unicorn/no-nested-ternary
      params?.airport
      ? decodeURIComponent(params.airport)
      : searchParams.get('airport') || undefined;

  const [selectedAirport, setSelectedAirport] = React.useState<string | undefined>(
    () => {
      if (!airportParam || !airports || airports.length === 0) return;

      // ✅ Match airport name more accurately
      return airports.find(
        airport =>
          airport.airport?.name.toLowerCase().replaceAll(/\s+/g, '-') ===
          airportParam.toLowerCase().replaceAll(/\s+/g, '-')
      )?.airport?.name;
    }
  );
  const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);
  const t = useTranslations('search_card.search_airport');
  const scrollRef = React.useRef<HTMLDivElement>(null);
  const popoverRef = React.useRef<HTMLDivElement>(null);
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = event.target.value.toLowerCase();
    setSearchTerm(searchValue);

    setFilteredCountries(
      airports
        .map(airport => ({
          ...airport,
          airports:
            airport.airport?.name.toLowerCase().includes(searchValue) ||
            airport.airport?.location?.country?.name
              .toLowerCase()
              .includes(searchValue)
              ? airport.airport?.name
              : [],
        }))
        .filter(
          country =>
            country.airports.length > 0 ||
            country.airport?.location?.country?.name
              ?.toLowerCase()
              .includes(searchValue)
        )
    );
  };

  // Group airports by country
  // eslint-disable-next-line unicorn/no-array-reduce
  const groupedByCountry = GroupAirportsByCountry(filteredCountries);

  const displayCountries = selectedCountry
    ? Object.entries(groupedByCountry).filter(
        ([country]) => country === selectedCountry
      )
    : Object.entries(groupedByCountry);

  const scrollLeft = () => {
    if (scrollRef.current) {
      const container = scrollRef.current;
      const scrollAmount = container.offsetWidth / 3;
      container.scrollBy({ left: -scrollAmount, behavior: 'smooth' });
    }
  };

  const scrollRight = () => {
    if (scrollRef.current) {
      const container = scrollRef.current;
      const scrollAmount = container.offsetWidth / 3;
      container.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    }
  };

  const handleAirportSelect = (airport: string) => {
    setSelectedAirport(airport);
    onAirportSelect(airport);
    setIsPopoverOpen(false);
    setIsAlertOpen(false);
  };

  const handleTriggerClick = () => {
    if (isAutoScroll && popoverRef.current) {
      const targetTop =
        popoverRef.current.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({
        top: targetTop - 150, // Adjust as needed for header spacing
        behavior: 'smooth',
      });
    }
  };

  return (
    <>
      <div className="md:block hidden">
        <Popover open={isPopoverOpen} onOpenChange={setIsPopoverOpen}>
          <div ref={popoverRef} className="min-w-80">
            <PopoverTrigger asChild>
              <Button
                variant={ButtonVariant}
                leftIcon="AirplaneIcon"
                radius={buttonRadius}
                size={buttonSize}
                fullWidth
                leftAlignText
                isActive={isPopoverOpen}
                onClick={handleTriggerClick}
              >
                {selectedAirport || t('placeholder')}
              </Button>
            </PopoverTrigger>
            <PopoverContent
              variant="compact"
              rounded="xl"
              title={t('input_form.title')}
              className="z-50"
              side={popoverSide}
            >
              <Input
                type="text"
                placeholder={t('input_form.placeholder')}
                value={searchTerm}
                onChange={handleSearch}
                ring={false}
                startIcon="SearchRoundedIcon"
                iconColor="primary"
                iconSize={8}
                roundedFull
                color="bg-inputFieldDefaultBackground"
                inputSize="medium"
              />

              <div className="space-y-1 bg-inputFieldDefaultBackground rounded-2xl p-3 mt-4 h-full w-full">
                <div className="flex items-center space-x-1 p-2 w-[20rem] lg:w-[25rem]">
                  <Button
                    onClick={scrollLeft}
                    variant="filled"
                    size="small"
                    radius="fullRounded"
                    iconColor="light"
                    color="bg-inputFieldDefaultBackground"
                  >
                    <Icon name="ChevronLeftRoundedIcon" iconColor="light" size={8} />
                  </Button>

                  <div
                    ref={scrollRef}
                    className="flex items-center space-x-6 overflow-hidden font-regular text-md w-full relative px-3"
                    style={{
                      maskImage:
                        'linear-gradient(to right, transparent, black 10%, black 90%, transparent)',
                    }}
                  >
                    <Button
                      variant="link"
                      onClick={() => setSelectedCountry(undefined)}
                      size="small"
                      isActive={selectedCountry === undefined}
                    >
                      {t('all_countries')}
                    </Button>
                    {Object.keys(groupedByCountry).map(country => (
                      <Button
                        key={country}
                        variant="link"
                        isActive={selectedCountry === country}
                        size="small"
                        onClick={() => setSelectedCountry(country)}
                      >
                        {country}
                      </Button>
                    ))}
                  </div>

                  <Button
                    onClick={scrollRight}
                    variant="filled"
                    size="small"
                    radius="fullRounded"
                    iconColor="light"
                  >
                    <Icon
                      name="ChevronRightRoundedIcon"
                      iconColor="light"
                      size={8}
                    />
                  </Button>
                </div>

                <div className="mt-2 max-h-48 overflow-y-auto mx-5">
                  {displayCountries.length > 0 ? (
                    displayCountries.map(([country, { countryFlag, airports }]) => (
                      <div key={country}>
                        <h2 className="text-base font-semibold py-2 flex items-center gap-2">
                          {countryFlag && countryFlag !== 'unknown' && (
                            <Image
                              src={countryFlag}
                              alt={`${country}`}
                              width={24}
                              height={16}
                              className="rounded-sm"
                            />
                          )}{' '}
                          {country}
                        </h2>

                        <ul className="font-normal space-y-1 pb-2">
                          {airports.map(airport => (
                            <li key={airport.id}>
                              <Button
                                variant="link"
                                size="small"
                                onClick={() =>
                                  handleAirportSelect(airport.airport?.name || '')
                                }
                              >
                                {airport.airport?.name}{' '}
                              </Button>
                            </li>
                          ))}
                        </ul>
                      </div>
                    ))
                  ) : (
                    <div className="text-center text-sm text-gray-500 py-4">
                      {t('no_airport_was_found')}
                    </div>
                  )}
                </div>
              </div>
            </PopoverContent>
          </div>
        </Popover>
      </div>
      <div className="md:hidden visible">
        <Button
          variant={ButtonVariant}
          leftIcon="AirplaneIcon"
          radius={buttonRadius}
          size={buttonSize}
          fullWidth
          leftAlignText
          isActive={isAlertOpen}
          onClick={() => setIsAlertOpen(true)}
        >
          {selectedAirport || t('placeholder')}
        </Button>
        <AlertDialog open={isAlertOpen} onOpenChange={setIsAlertOpen}>
          <AlertDialogContent className="rounded-0 w-full px-4 h-full">
            <AlertDialogTitle className=" mb-4 pt-6">
              <div className="flex justify-between items-center pb-2">
                <div className="flex items-center space-x-3">
                  <span className="text-black text-left font-normal leading-9">
                    {t('input_form.title')}
                  </span>
                </div>
                <AlertDialogCancel className="m-0 p-0 border-none">
                  <Icon name="CloseRoundedIcon" iconColor="blueGray" size={9} />
                </AlertDialogCancel>
              </div>
            </AlertDialogTitle>

            <Input
              type="text"
              placeholder={t('input_form.placeholder')}
              value={searchTerm}
              onChange={handleSearch}
              ring={false}
              startIcon="SearchRoundedIcon"
              iconColor="default"
              iconSize={8}
              roundedFull
              color="bg-inputFieldDefaultBackground"
              inputSize="medium"
            />
            <div className="space-y-1 bg-inputFieldDefaultBackground rounded-t-2xl mt-4">
              <div className="flex items-center space-x-1 p-4 w-full">
                <Button
                  onClick={scrollLeft}
                  variant="filled"
                  size="small"
                  radius="fullRounded"
                  iconColor="light"
                  color="bg-inputFieldDefaultBackground"
                >
                  <Icon name="ChevronLeftRoundedIcon" iconColor="light" size={8} />
                </Button>

                <div
                  ref={scrollRef}
                  className="flex items-center space-x-6 overflow-hidden font-regular text-md w-full relative px-3"
                  style={{
                    maskImage:
                      'linear-gradient(to right, transparent, black 10%, black 90%, transparent)',
                  }}
                >
                  <Button
                    variant="link"
                    onClick={() => setSelectedCountry(undefined)}
                    size="small"
                    isActive={selectedCountry === undefined}
                  >
                    {t('all_countries')}
                  </Button>
                  {Object.keys(groupedByCountry).map(country => (
                    <Button
                      key={country}
                      variant="link"
                      isActive={selectedCountry === country}
                      size="small"
                      onClick={() => setSelectedCountry(country)}
                    >
                      {country}
                    </Button>
                  ))}
                </div>

                <Button
                  onClick={scrollRight}
                  variant="filled"
                  size="small"
                  radius="fullRounded"
                  iconColor="light"
                >
                  <Icon name="ChevronRightRoundedIcon" iconColor="light" size={8} />
                </Button>
              </div>
            </div>
            <div className="h-full overflow-y-auto bg-inputFieldDefaultBackground rounded-b-2xl px-8 mb-4">
              {displayCountries.length > 0 ? (
                displayCountries.map(([country, { countryFlag, airports }]) => (
                  <div key={country}>
                    <h2 className="text-base font-semibold py-2 flex items-center gap-2">
                      {countryFlag && countryFlag !== 'unknown' && (
                        <Image
                          src={countryFlag}
                          alt={`${country}`}
                          width={24}
                          height={16}
                          className="rounded-sm"
                        />
                      )}{' '}
                      {country}
                    </h2>
                    <ul className="font-normal space-y-1 pb-2">
                      {airports.map(airport => (
                        <li key={airport.id}>
                          <Button
                            variant="link"
                            size="small"
                            onClick={() =>
                              handleAirportSelect(airport.airport?.name || '')
                            }
                          >
                            {airport.airport?.name}{' '}
                          </Button>
                        </li>
                      ))}
                    </ul>
                  </div>
                ))
              ) : (
                <div className="text-center text-sm text-gray-500 py-4">
                  {t('no_airport_was_found')}
                </div>
              )}
            </div>
          </AlertDialogContent>
        </AlertDialog>
      </div>
    </>
  );
};
